<template>
   <div>
      <Header />
      <Wods/>
      <Footer /> 
   </div>
</template>

<script>
import Wods from "../components/Wods/Wods.vue"

export default {
   components:{
      Wods
   }
};
</script>

<style></style>
