<template>
   <div class="ma-6">
      <Wod @save="$refs.ok_noti.Show()" ref="wod" />
      <Dialog message="¿Desea eliminar el wod?" @result_ok="confirmDeleteItem()" ref="deleteItem" />
      <Notification type="error" message="Error" ref="error_noti" />
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <v-data-table :loading="isloading" :headers="headers" :items="$store.state.wods" :items-per-page="5" class="elevation-1 mb-8" :search="search">
         <template v-slot:top>
            <v-toolbar flat color="secondary">
               <v-btn @click="newItem()"><v-icon left>mdi-plus-circle</v-icon>Nuevo wod</v-btn>
               <v-btn @click="initialize" icon small class="ml-2"><v-icon small>mdi-autorenew</v-icon></v-btn>
               <v-spacer></v-spacer>
               <v-text-field v-model="search" label="Search" clearable hide-details></v-text-field>
            </v-toolbar>
         </template>
         <template v-slot:[`item.tipo`]="{ item }">
            <v-icon small color="warning" v-if="item.tipo == 'T'">mdi-clock-outline</v-icon>
            <v-icon small color="success" v-else>mdi-repeat-variant</v-icon>
         </template>
         <template v-slot:[`item.actions`]="{ item }">
            <v-btn small icon class="mr-2" :href="$c.API_URL + '/public/' + item.img" target="_blank" rel="noopener noreferrer"
               ><v-icon small>mdi-image</v-icon></v-btn
            >
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import Wod from "./Wods/Wod.vue";

export default {
   components: {
      Wod,
   },
   data() {
      return {
         headers: [
            { text: "ID", value: "wod_id" },
            { text: "Nombre", value: "nombre" },
            { text: "Tipo", value: "tipo" },
            { text: "Acciones", value: "actions" },
         ],
         itemToDelete: null,
         isloading: true,
         search: "",
      };
   },
   methods: {
      async initialize() {
         try {
            this.isloading = true;
            await this.$store.dispatch("getWods");
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      async newItem() {
         this.$refs.wod.Show({
            wod_id: null,
            nombre: "",
            tipo: "T",
         });
      },
      async editItem(item) {
         this.$refs.wod.Show({
            wod_id: item.wod_id,
            nombre: item.nombre,
            tipo: item.tipo,
         });
      },
      async deleteItem(item) {
         this.itemToDelete = item;
         this.$refs.deleteItem.Show();
      },
      async confirmDeleteItem() {
         try {
            this.isloading = true;
            let res = null;
            res = await this.$store.dispatch("deleteWod", {
               wod_id: this.itemToDelete.wod_id,
            });
            if (res == true) {
               this.$refs.ok_noti.Show();
            } else {
               throw res;
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
   },
   created() {
      this.initialize();
   },
};
</script>

<style></style>
