<template>
   <v-dialog v-model="dialog" max-width="600px">
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card :loading="isloading">
         <v-card-title>{{ title }}</v-card-title>
         <v-card-text>
            <form>
               <v-row>
                  <v-col>
                     <v-text-field
                        v-model="nombre"
                        label="Nombre"
                        :error-messages="nombreErrors"
                        required
                        @input="$v.nombre.$touch()"
                        @blur="$v.nombre.$touch()"
                     ></v-text-field>
                  </v-col>
                  <v-col>
                     <v-select
                        v-model="tipo"
                        label="Tipo"
                        :error-messages="tipoErrors"
                        required
                        :items="tipos"
                        item-text="text"
                        item-value="value"
                        @input="$v.tipo.$touch()"
                        @blur="$v.tipo.$touch()"
                     ></v-select>
                  </v-col>
               </v-row>
               <v-row v-if="!isEdit">
                  <v-col>
                     <v-file-input
                        v-model="img"
                        label="Imagen"
                        outlined
                        show-size
                        truncate-length="15"
                        :error-messages="filesErrors"
                        @input="$v.files.$touch()"
                        @blur="$v.files.$touch()"
                     ></v-file-input>
                  </v-col>
               </v-row>
            </form>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">Cancelar</v-btn>
            <v-btn :loading="isloading" color="primary" @click="save">Guardar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
   mixins: [validationMixin],
   validations: {
      nombre: {
         required,
         maxLength: maxLength(30),
      },
      tipo: {
         required,
      },
      files: {
         file_size: function () {
            if (this.img) {
               return this.img.size < 1000000;
            }
            return true;
         },
         file_type: function () {
            if (this.img) {
               return !(this.img.type != "image/jpeg" && this.img.type != "image/jpg" && this.img.type != "image/png");
            }
            return true;
         },
      },
   },
   data: function () {
      return {
         wod_id: "",
         nombre: "",
         tipo: null,
         img: null,
         dialog: false,
         isloading: false,
         tipos: [
            {
               text: "Tiempo",
               value: "T",
            },
            {
               text: "Repeticiones",
               value: "R",
            },
         ],
      };
   },
   watch: {
      dialog: function (val) {
         if (!val) {
            this.close();
            this.$emit("close");
         }
      },
   },
   methods: {
      save: async function () {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = null;
               if (!this.isEdit) {
                  res = await this.$store.dispatch("insertWod", {
                     nombre: this.nombre,
                     img: this.img,
                     tipo: this.tipo,
                  });
               } else {
                  res = await this.$store.dispatch("updateWod", {
                     wod_id: this.wod_id,
                     nombre: this.nombre,
                     tipo: this.tipo,
                     img: this.img,
                  });
               }
               if (res == true) {
                  this.$emit("save");
                  this.close();
               } else {
                  throw res;
               }
            } else {
               throw "error";
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      close: function () {
         this.dialog = false;
         this.$v.$reset();
      },
      Show: function (i) {
         this.dialog = true;
         this.wod_id = i.wod_id;
         this.nombre = i.nombre;
         this.tipo = i.tipo;
         this.img = null;
      },
   },
   computed: {
      isEdit() {
         if (this.wod_id) {
            return true;
         } else {
            return false;
         }
      },
      title() {
         if (this.wod_id) {
            return "Editar wod";
         } else {
            return "Crear wod";
         }
      },
      nombreErrors() {
         const errors = [];
         if (!this.$v.nombre.$dirty) return errors;
         !this.$v.nombre.required && errors.push("El nombre es requerido");
         !this.$v.nombre.maxLength && errors.push("Debe contener maximo 30 caracteres");
         return errors;
      },
      tipoErrors() {
         const errors = [];
         if (!this.$v.tipo.$dirty) return errors;
         !this.$v.tipo.required && errors.push("El tipo es requerido");
         return errors;
      },
      filesErrors() {
         const errors = [];
         if (!this.$v.files.$dirty) return errors;
         !this.$v.files.file_size && errors.push("Uno o mas archivos son mayores a 1MB");
         !this.$v.files.file_type && errors.push("Uno o mas archivos no son de tipo imagen");
         return errors;
      },
   },
};
</script>
